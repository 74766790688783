import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer style={{height: '25px'}} className="bg-dark text-white p-0 text-center">
      <Container>
        <p>&copy; 2024 Jordan Webb. All rights reserved.</p>
      </Container>
    </footer>
  );
};

export default Footer;