import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const Development = () => {
  const [githubData, setGithubData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [languagesData, setLanguagesData] = useState([]);

  useEffect(() => {
    axios.get('https://api.github.com/users/surgeNexus')
      .then(response => {
        setGithubData(response.data);
        return axios.get('https://api.github.com/users/surgeNexus/repos');
      })
      .then(async reposResponse => {
        const repos = reposResponse.data;
        const languages = {};

        const languagePromises = repos.map(repo => {
          return axios.get(repo.languages_url).then(langResponse => {
            for (const [key, value] of Object.entries(langResponse.data)) {
              languages[key] = (languages[key] || 0) + value;
            }
          });
        });

        await Promise.all(languagePromises);
        return languages;
      })
      .then(languages => {
        const languagesArray = Object.entries(languages).map(([key, value]) => ({
          name: key,
          value,
        }));
        setLanguagesData(languagesArray);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the GitHub data!", error);
        setLoading(false);
      });
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#845EC2', '#D65DB1', '#FF6F91'];

  return (
    <Container className="development-container mt-5">
      <Row>
        <Col>
          <h3>Software Development</h3>
          <p className='mb-5'>Jordan is proficient in various programming languages and technologies, including React, Node.js, JavaScript, Python, VB.NET, and Java.</p>
          <h3>Notable Projects</h3>
          <Row>
            <Col className='mb-5' md={4}>
              <Card style={{height: '100%'}}>
                <Card.Body className='d-flex flex-column'>
                  <Card.Title>SMARC</Card.Title>
                  <Card.Text>
                    The website w4olb.org is dedicated to the Smoky Mountain Amateur Radio Club (SMARC), which serves the East Tennessee area from the foothills of the Great Smoky Mountains National Park. The club is a community hub for amateur radio enthusiasts, offering a range of activities and resources.
                  </Card.Text>
                  <Card.Link className='mt-auto btn btn-danger' href="https://w4olb.org" target="_blank" rel="noopener noreferrer">View Website</Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-5' md={4}>
              <Card style={{height: '100%'}}>
                <Card.Body className='d-flex flex-column'>
                  <Card.Title>WB4GBI</Card.Title>
                  <Card.Text>
                  The website wb4gbi.com is dedicated to the WB4GBI Repeater System, which serves East Tennessee. This repeater system is managed by Tim Berry, WB4GBI. The site offers a wealth of information about the various repeaters under the WB4GBI call sign, including technical specifications, locations, and operational guidelines.
                  </Card.Text>
                  <Card.Link className='mt-auto btn btn-danger' href="https://wb4gbi.com" target="_blank" rel="noopener noreferrer">View Website</Card.Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-5' md={4}>
              <Card style={{height: '100%'}}>
                <Card.Body className='d-flex flex-column'>
                  <Card.Title>ETSKYWARN</Card.Title>
                  <Card.Text>
                  The website etskywarn.org is dedicated to East Tennessee Skywarn, a volunteer program that focuses on weather spotting and reporting for the National Weather Service (NWS) in Morristown, TN. The program involves trained amateur radio operators who provide real-time reports during severe weather events. 
                  </Card.Text>
                  <Card.Link className='mt-auto btn btn-danger' href="https://etskywarn.com" target="_blank" rel="noopener noreferrer">View Website</Card.Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <h3>Repositories</h3>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <Row className='d-flex align-items-center'>
              <Col sm={12}>
                <ResponsiveContainer className='mb-5' width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={languagesData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      fill="#8884d8"
                      label
                    >
                      {languagesData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Col>
              <Col sm={12}>
                {
                  githubData && (
                    <div className='d-flex justify-content-center'>
                      <Card style={{width: "max-content", padding: '20px'}} className='mb-5'>
                        <Card.Body>
                          <Card.Title>GitHub Statistics</Card.Title>
                          <p>Username: {githubData.login}</p>
                          <p>Public Repositories: {githubData.public_repos}</p>
                          <a href={githubData.html_url} target="_blank" rel="noopener noreferrer">View GitHub Profile</a>
                        </Card.Body>
                      </Card>
                    </div>
                  )
                }
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Development;
