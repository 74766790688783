import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import JordanPicture from '../images/IMG_0907 copy.webp';

const About = () => {
  return (
    <Container fluid>
        <Container className="about-container mt-5">
        <Row className='d-flex align-items-center'>
            <Col>
                <h2>Early life and Amateur Radio Beginnings</h2>
                <p>
                    Jordan Webb, known by his amateur radio callsigns NA4WX and K4AVG, has been an enthusiastic member of the amateur radio community since he was 12 years old. Starting his journey in 2002 with the callsign KI4AVG as a Technician class operator, Jordan quickly developed a passion for radio communication. Over the years, he has advanced through the licensing ranks, earning respect and recognition within the community.
                </p>
            </Col>
            <Col className='d-flex justify-content-end'>
                <Image className='about-img' src={JordanPicture} />
            </Col>
        </Row>
        </Container>
        <Container className="about-container mt-5">
        <Row>
            <Col>
                <h2>Contributions to Weather Spotting</h2>
                <p>
                Jordan's dedication to amateur radio is matched by his commitment to public safety. He serves as the District 5 Assistant Skywarn Coordinator and a net control station for East Tennessee SKYWARN. In these roles, he coordinates weather spotting activities, monitors severe weather conditions, and ensures timely and accurate communication during weather emergencies. His efforts contribute significantly to the safety and preparedness of the East Tennessee community.
                </p>
            </Col>
        </Row>
        </Container>
        <Container className="about-container mt-5">
            <Row>
                <Col>
                    <h2>Professional Software Development</h2>
                    <p>
                    Beyond his amateur radio activities, Jordan is a skilled software developer with extensive experience in modern web technologies. He is proficient in React, Node.js, JavaScript, Python, VB.NET, and Java. Jordan's GitHub profile, under the username surgeNexus, showcases a variety of projects that reflect his technical expertise and innovative thinking.
                    </p>
                </Col>
            </Row>
        </Container>
        <Container className="about-container mt-5">
            <Row>
                <Col>
                    <h2>Personal Interests</h2>
                    <p>
                    In addition to his professional and community activities, Jordan enjoys exploring new technologies, participating in ham radio contests, and engaging in outdoor activities. His multifaceted interests and commitment to lifelong learning drive his continuous growth and contributions in both the amateur radio and software development fields.
                    </p>
                </Col>
            </Row>
        </Container>
    </Container>
  );
};

export default About;
