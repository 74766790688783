import React from 'react';
import { Container } from 'react-bootstrap';

const Activities = () => {
  return (
    <Container className="activities-container mt-5">
      <h2>Activities</h2>
      <p>
        Jordan Webb is actively involved in various amateur radio and community activities. As the District 5 Assistant Skywarn Coordinator and a net control station for East Tennessee SKYWARN, he plays a vital role in weather spotting and community safety.
      </p>
    </Container>
  );
};

export default Activities;
