import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import hamRadioImage from '../images/ham_radio.jpg';
import fieldDayImage from '../images/field_day.jpg';
import radioSetupImage from '../images/radio_setup.jpg';

const HamRadio = () => {
  return (
    <Container className="hamradio-container mt-5">
      <Row>
        <Col>
          <h2>Amateur Radio (Ham Radio)</h2>
          <p>
            Jordan Webb, known by his callsigns NA4WX, is a dedicated amateur radio operator. His journey in ham radio began at the age of 12, and he has since become an integral part of the community, providing vital communication services and participating in numerous radio events.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card>
            <Card.Img variant="top" src={hamRadioImage} alt="Ham Radio" />
            <Card.Body>
              <Card.Title>Introduction to Ham Radio</Card.Title>
              <Card.Text>
                Amateur radio, also known as ham radio, allows licensed operators to communicate over various frequencies. It plays a crucial role in emergency communication and community events.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Img style={{position: "relative"}} variant="top" src={fieldDayImage} alt="Field Day" />
            <Card.Body>
              <Card.Title>Field Day Events</Card.Title>
              <Card.Text>
                Jordan regularly participates in Field Day events, where amateur radio operators set up temporary stations and practice emergency response communications.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Img variant="top" src={radioSetupImage} alt="Radio Setup" />
            <Card.Body>
              <Card.Title>Advanced Radio Setup</Card.Title>
              <Card.Text>
                Jordan's advanced radio setup includes high-frequency transceivers, antennas, and digital modes, enabling him to communicate with operators worldwide.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Roles and Contributions</h3>
          <p>
            As the District 5 Assistant Skywarn Coordinator, Jordan plays a pivotal role in monitoring severe weather conditions and providing real-time reports to the National Weather Service. His commitment to public safety and communication excellence is evident in his proactive approach to emergency preparedness.
          </p>
          <h3>Community Involvement</h3>
          <p>
            Jordan is an active member of the Smoky Mountain Amateur Radio Club and serves as the webmaster for the club's website. He organizes and participates in community events, providing training and support to new amateur radio operators.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default HamRadio;
