import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import smallLogo from '../images/NA4WX Logo Small.png'

const Navigation = () => {

    const handleCollapse = () => {
        let nav = document.getElementById("collapsible-navbar-nav");
        let btn = document.getElementById("navbarBtn");
        if(!nav.classList.show){
            nav.classList.remove("show");
        }
        btn.classList.add("collapsed");
      };

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbarBtn" />
                <Navbar.Brand>
                    <img className='img-fluid nav-img' src={smallLogo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Collapse id="collapsible-navbar-nav">
                    <Nav className="me-auto" onClick={handleCollapse}>
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/hamradio">Ham Radio</Nav.Link>
                        <Nav.Link as={Link} to="/development">Development</Nav.Link>
                        {/* <Nav.Link as={Link} to="/activities">Activities</Nav.Link>
                        <Nav.Link as={Link} to="/weather-spotting">Weather Spotting</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/contact">Contact</Nav.Link> */}
                        <Nav.Link as={Link} to="/about">About</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        <Nav.Link as={Link} target="_blank" to="https://meet.na4wx.com">Meetings</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
