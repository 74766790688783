import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
// import SendEmail from '../sendEmail';

const Contact = () => {

    const [ messageDetails, setMessageDetails ] = useState({});

    const handleChange = e => {
        e.preventDefault();
        setMessageDetails({...messageDetails, [e.target.name]: e.target.value});

    }
    
    const handleSubmit = e => {
        e.preventDefault();
        // SendEmail({from: messageDetails.email, subject: "Contact request from: " + messageDetails.name, message: messageDetails.message});
        setMessageDetails();
    }


    return (
        <Container className="contact-container mt-5">
            <h2>Contact</h2>
            <Form>
                <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control onChange={handleChange} name='name' type="text" placeholder="Enter your name" />
                </Form.Group>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control onChange={handleChange} name='email' type="email" placeholder="Enter your email" />
                </Form.Group>
                <Form.Group controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control onChange={handleChange} name='message' as="textarea" rows={3} placeholder="Your message" />
                </Form.Group>
                <Button className='mt-2 btn-block' variant="primary" type="submit">
                Submit
                </Button>
            </Form>
        </Container>
    );
};

export default Contact;
