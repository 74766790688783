import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Activities from './pages/Activities';
import WeatherSpotting from './pages/WeatherSpotting';
import Development from './pages/Development';
import Contact from './pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import HamRadio from './pages/HamRadio';

const pageVariants = {
  initial: {
    opacity: 0,
    y: "-100vh"
  },
  in: {
    opacity: 1,
    y: 0
  },
  out: {
    opacity: 0,
    y: "100vh"
  }
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5
};

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <main>
          <Routes>
            <Route 
              path="/" 
              element={
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  <Home />
                </motion.div>
              } 
            />
            <Route 
              path="/development" 
              element={
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  <Development />
                </motion.div>
              } 
            />
            <Route 
              path="/hamradio" 
              element={
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  <HamRadio />
                </motion.div>
              } 
            />
            <Route 
              path="/about" 
              element={
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  <About />
                </motion.div>
              } 
            />
            <Route 
              path="/activities" 
              element={
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  <Activities />
                </motion.div>
              } 
            />
            <Route 
              path="/weather-spotting" 
              element={
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  <WeatherSpotting />
                </motion.div>
              } 
            />
            <Route 
              path="/contact" 
              element={
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  <Contact />
                </motion.div>
              } 
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
