import React from 'react';
import { Container } from 'react-bootstrap';

const WeatherSpotting = () => {
  return (
    <Container className="weather-container mt-5">
      <h2>Weather Spotting</h2>
      <p>
        Jordan Webb serves as the District 5 Assistant Skywarn Coordinator, helping monitor and report weather conditions to ensure community safety during severe weather events.
      </p>
    </Container>
  );
};

export default WeatherSpotting;
