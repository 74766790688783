import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import stormImage from '../images/storm.jpg';
import hamRadioImage from '../images/ham_radio.jpg';
import softwareDevImage from '../images/software_dev.jpg';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    const nav = useNavigate();

    const handleNav = e => {
        nav(e, {replace: true});
    }

  return (
    <Container className="home-container mt-5">
      <Row>
        <Col>
          <h1>NA4WX</h1>
          <p>
            Jordan Webb, known as NA4WX in the amateur radio community, is a dedicated weather spotter, skilled software developer, and active member of the East Tennessee community.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card className='hover-pointer' onClick={() => handleNav("hamradio")}>
            <Card.Img variant="top" src={hamRadioImage} alt="Ham Radio" />
            <Card.Body>
              <Card.Title>Amateur Radio</Card.Title>
              <Card.Text>
                NA4WX, Jordan is an experienced amateur radio operator, contributing to the community through communication, emergency services, and technology.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='hover-pointer' onClick={() => handleNav("development")}>
            <Card.Img variant="top" src={softwareDevImage} alt="Software Development" />
            <Card.Body>
              <Card.Title>Software Development</Card.Title>
              <Card.Text>
                Proficient in React, Node.js, JavaScript, Python, VB.NET, and Java, Jordan develops innovative software solutions and shares his projects on GitHub.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='hover-pointer' onClick={() => handleNav("about")}>
            <Card.Img variant="top" src={stormImage} alt="Storm" />
            <Card.Body>
              <Card.Title>Weather Spotting</Card.Title>
              <Card.Text>
                Jordan plays a crucial role in monitoring severe weather conditions, gathering data, and ensuring public safety as a District 5 Assistant Skywarn Coordinator.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
